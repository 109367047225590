import NavBar from './Components/HomeCom/NavBar';
import Page from './Pages/Page';

function App() {
  return (
    <div className="App font-poppins overflow-hidden">
      <Page/>
      
    </div>
  );
}

export default App;
