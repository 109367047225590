import React from 'react'
import CloudCard from './CloudCard'
import NavBar from '../../HomeCom/NavBar'
import Choose from '../Remote/Choose'
import Footer from '../../HomeCom/Footer'

const CloudDeatail = () => {
  return (
    <section>
      <NavBar/>
      <CloudCard/>
      <Choose/>
      <Footer/>
    </section>
  )
}

export default CloudDeatail
