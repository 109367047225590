import React from 'react'
import MainHome from '../../Components/HomeCom/MainHome'

const Home = () => {
  return (
    <div>
      <MainHome/>
    </div>
  )
}

export default Home
