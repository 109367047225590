import React from 'react'
import MainAbout from '../../Components/AboutCom/MainAbout'

const About = () => {
  return (
    <div>
      <MainAbout/>
    </div>
  )
}

export default About
